export const headerCode = `headers: {
  "party-id": "GBXRIBT001",
  "client-id": "6789aa",
  "x-api-key": "EdX2C9Yeku3dVLSaYe2RI2iBnFXoSEgB11gsOwSX",
  "authorization": \`Bearer \${accessToken}\`,
  "Content-Type": "application/json"
}`

export const exampleErrorResponses = {
  404: `{
    "message": "Label does not exist",
    "errors": [
      {
         "message": "Not Found",
          "code": "1"
      }
    ]
  }`,
  202: `{
    "message": "Label are still being processed"
  }`
}