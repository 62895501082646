import React, { useEffect, useState } from 'react';
import AMIButton from '../../../ui-components/button/Button';
import AMIDatePicker from '../../../ui-components/date-picker/DatePicker';
import Icon from '../../../ui-components/icon/Icon';
import AMIInfo from '../../../ui-components/info-box/InfoBox';
import AMISelect from '../../../ui-components/select/Select';
import AMIPopover from '../../../ui-components/popover/Popover';
import { ActivePage } from '../steps/Steps';
import './OptionsPage.scss';
import {
  useAppDispatch,
  useAppSelector
} from "../../../app/hooks";
import {
  customerDetailsSelector,
  preferenceSelector
} from "../../../features/customerSlice";
import {
  LabelType,
  manifestActions,
  manifestSelector
} from "../../../features/manifestSlice";
import AMIInput from '../../../ui-components/input/Input';
import AMIFormElement from '../../../ui-components/form-element/FormElement';
import { createRequest } from "./ManifestBookingRequestHandler";
import SenderDetailsModal from './sender-details-modal/SenderDetailsModal';
import { placeManifestBooking } from '../../../utilities/APIUtilities';
import { AMIAlert } from '../../../ui-components/UiComponents';
import { ImperialMetric } from '../../../features/bookingSlice';
import { snakeCaseConvertor } from '../../../utilities/HelperUtilities';
import { appDataSelector } from '../../../features/appDataSlice';
import { ManifestBookingRequest } from './ManifestBookingRequestMapper';

const OptionsPage: React.FC<{changeStep: (arg0: ActivePage) => void}> = ({changeStep}) => {

  const customer = useAppSelector(customerDetailsSelector);
  const customerPreferences = useAppSelector(preferenceSelector);
  const manifest = useAppSelector(manifestSelector);
  const sender = manifest.senderContactDetails;
  const email = customer.logonEmail;
  const currencyCode = customer.countryOfResidence.currencyCode;
  const imperialOrMetric = manifest.imperialOrMetric;
  const apiConfig = useAppSelector(appDataSelector).apiConfig;

  const dispatch = useAppDispatch();

  const [isOrderBooking, setIsOrderBooking] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [isSenderDetailsModalVisible, setIsSenderDetailsModalVisible] = useState(false);
  const [formattedSenderDetails, setFormattedSenderDetails] = useState('');
  const [bookingError, setBookingError] = useState('');
  const [services, setServices] = useState<{title: string, value: string}[]>([]);

  useEffect(() => {
    setImperialOrMetric(customer.countryOfResidence.value === 'US' ? ImperialMetric.IMPERIAL : ImperialMetric.METRIC);
    setServices(getServices())
  }, [])

  useEffect(() => {
    formatSenderDetails();
  }, [sender]);

  const getServices = () => {
    let services: {title: string, value: string}[] = [];
    if (customer.countryOfResidence.value === 'US') {
      if (manifest.isInternational) {
        const isDocs = manifest.csvData[0].shipment_type === 'DOCS'
        services = (
          [
            {title: 'FedEx International Priority', value: 'FDX-US-INTLPR-COL'},
            {title: 'DHL Express Worldwide', value: isDocs ? 'DHL-US-EXT-DOC-COL' : 'DHL-US-EXT-ND-COL'},
          ]
        )
      } else {
        services = [
          {title: 'FedEx Standard Overnight', value: 'FDX-US-DOM-SON-COL'},
          {title: 'FedEx 2 Day', value: 'FDX-US-DOM-2D-COL'},
          {title: 'FedEx Ground', value: 'FDX-US-DOM-GD-COL'}
        ]
      }
    }

    if (customer.countryOfResidence.value === 'GB' && manifest.isInternational) {
      services = ([
        {title: 'FedEx International Priority Express Drop In', value: 'FDX-UK-EXT-IPX-DI'},
        {title: 'FedEx International Priority Drop In', value: 'FDX-UK-INTLPR-DI'},
      ])
    }

    return services;
  }

  const setImperialOrMetric = (value: string) => {
    dispatch(manifestActions.setImperialOrMetric(value))
  }

  const onDatePick = (date: number) => {
    dispatch(manifestActions.saveReadyDate(date));
  }

  const onLabelTypeChange = (event: any) => {
    dispatch(manifestActions.setLabelType(event))
  }

  const onServiceChange = (event: any) => {
    manifest.isInternational ? dispatch(manifestActions.setInternationalService(event)) : dispatch(manifestActions.setDomesticService(event));
  }

  const errHandler: any = {
    readyDate: {
      criteria: manifest.readyDate,
      message: 'This field cannot be empty'
    },
    senderNameErr: {
      criteria: sender.name.length > 2
      && sender.name.length < 36
      && sender.organisation
      && sender.addressLine1.length > 2
      && sender.addressLine1.length < 36
      && sender.cityTown.length > 2
      && sender.cityTown.length < 36
      && sender.countryISO.length === 2
      && sender.telephone.length > 5
      && sender.telephone.length < 16,
      message: 'Missing information, please complete mandatory fields'
    },
    labelTypeErr: {
      criteria: manifest.labelType,
      message: 'A label type must be chosen'
    },
    service: {
      criteria: manifest.isInternational ? manifest.internationalService.value : manifest.domesticService.value,
      message: 'A service must be chosen'
    }
  }

  const onBookOrderClick = async() => {
    setShowErrors(true);
    setBookingError('');

    for (const error in errHandler) {
      if (!errHandler[error].criteria) {
        return;
      }
    }

    setIsOrderBooking(true);

    const request: ManifestBookingRequest = createRequest(customer, customerPreferences, manifest);

    const bookingResponse = await placeManifestBooking(request, apiConfig, customer)

    setIsOrderBooking(false)

    if (bookingResponse) changeStep(ActivePage.FINISH_PAGE);
    else setBookingError('An error occurred while placing the booking. Please try again or contact customer services.');
  }

  const toggleReadyDatePopover = () => {
    setIsPopoverVisible(!isPopoverVisible)
  }

  const readyDateLabel = (
    <div
      className="options-page__labels"
    >Ready Date
      <span
        onMouseEnter={toggleReadyDatePopover}
        onMouseLeave={toggleReadyDatePopover}
      >
        <Icon
          name="CircleQuestion"
          color="var(--primary)"
          style={{marginLeft: '4px'}}
        />
        <AMIPopover
          isVisible={isPopoverVisible}
        >This is the date for collection / drop off
        </AMIPopover>
      </span>
    </div>
  )

  const toggleModal = () => {
    setIsSenderDetailsModalVisible(!isSenderDetailsModalVisible)
  }

  const formatSenderDetails = () => {
    if (
      sender.name
      && sender.organisation
      && sender.addressLine1
    ) {
      setFormattedSenderDetails(manifest.senderContactDetails.name
        + ', '
        + manifest.senderContactDetails.organisation
        + ', '
        + manifest.senderContactDetails.addressLine1)
    } else {
      setFormattedSenderDetails('')
    }
  }

  return (
    <div className="options-page">
      <div className="blank"/>
      <div className="options-page__content">

      <AMIFormElement
          label={readyDateLabel}
          className="options-page__content__full-width"
          errorMessage={
            showErrors
            && !errHandler.readyDate.criteria
            ? errHandler.readyDate.message
            : ''
          }
        >
          <AMIDatePicker
            size="large"
            placeholder="Required"
            style={{
              width: '100%'
            }}
            numFutureDays={7}
            datePicked={manifest.readyDate}
            onChange={onDatePick}
          />

        </AMIFormElement>

        <AMIFormElement
          label="Service"
          className="options-page__content__full-width"
          errorMessage={
            showErrors
            && !errHandler.service.criteria
            ? errHandler.service.message
            : ''
          }
        >
          <AMISelect
            name="service"
            placeholder="Required"
            size="large"
            style={{
              width: '100%',
            }}
            defaultValue={manifest.isInternational ? manifest.internationalService : manifest.domesticService}
            options={services}
            onChange={onServiceChange}
          />
          Shipment costs will depend on which service is chosen, please contact customer services.
        </AMIFormElement>

        <AMIFormElement
          label="Sender Details"
          className="options-page__content__full-width"
          errorMessage={
            showErrors
            && !errHandler.senderNameErr.criteria
            ? errHandler.senderNameErr.message
            : ''
          }
        >
          <div onClick={toggleModal}>
            <AMIInput
              name="senderDetails"
              placeholder="Required"
              size="large"
              style={{
                width: '100%',
                cursor: 'pointer'
              }}
              readOnly
              value={formattedSenderDetails}
            />
          </div>
        </AMIFormElement>

        {isSenderDetailsModalVisible &&
          <SenderDetailsModal
            close={toggleModal}
          ></SenderDetailsModal>
        }

        <AMIFormElement
          label="Label Type"
          className="options-page__content__full-width"
          errorMessage={
            showErrors
            && !errHandler.labelTypeErr.criteria
            ? errHandler.labelTypeErr.message
            : ''
          }
        >
          <AMISelect
            name="label-type"
            placeholder="Required"
            size="large"
            style={{
              width: '100%',
            }}
            defaultValue={manifest.labelType}
            options={[
                {title: LabelType.PDF, value: LabelType.PDF},
                {title: LabelType.THERMAL, value: LabelType.THERMAL}
            ]}
            onChange={onLabelTypeChange}
          />
        </AMIFormElement>

        <AMIFormElement label="Imperial or Metric">
          <AMISelect
            name="imperial-metric"
            size="large"
            style={{width: '100%'}}
            defaultValue={{title: `${snakeCaseConvertor(imperialOrMetric)}`, value: `${imperialOrMetric}`}}
            options={[
                {title: "Metric", value: `${ImperialMetric.METRIC}`},
                {title: "Imperial", value: `${ImperialMetric.IMPERIAL}`}
            ]}
            onChange={(event: any) => setImperialOrMetric(event.value)}
          />
        </AMIFormElement>

        <AMIFormElement label="Currency">
          <AMISelect
            disabled={true}
            name="email"
            size="large"
            style={{width: '100%'}}
            defaultValue={{title: `${currencyCode}`, value: `${currencyCode}`}}
            options={[
                {title: `${currencyCode}`, value: `${currencyCode}`}
            ]}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Email to send labels to"
          className="options-page__content__full-width"
        >
          <AMISelect
            disabled={true}
            name="email"
            size="large"
            style={{
              width: '100%',
            }}
            defaultValue={{title: `${email}`, value: `${email}`}}
            options={[
                {title: `${email}`, value: `${email}`}
            ]}
          />
        </AMIFormElement>

        {bookingError && (
          <AMIAlert
            variant="error"
            style={{marginBottom: "24px"}}
          >{bookingError}</AMIAlert>
        )}

        <AMIButton
          className="options-page__content__full-width"
          size="large"
          style={{width: "100%"}}
          variant="primary"
          isSpinning={isOrderBooking}
          onClick={onBookOrderClick}
        >
          <Icon
            name="Parcel"
            color="var(--textLight)"
            style={{
              width: "24px",
              height: "24px",
              transform: "translateY(3px)"
            }}
          />
          Place Booking
        </AMIButton>

      </div>

      <div className="options-page__info">
        <AMIInfo title="How To">
          <p>Your uploaded data matches our requirements and is ready to be processed.</p>
          <p>To complete the booking please fill in all fields and then press the <strong>Place Booking</strong> button.</p>
          <p>If any details are incorrect, please contact customer services.</p>
        </AMIInfo>
      </div>
    </div>
  )
}

export default OptionsPage;